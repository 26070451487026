@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arbutus+Slab&display=swap");

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth !important;
  background: #f7f7f7;
}

.btn--outlined {
  background: none;
  border: 1px solid;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  padding: 12px 40px;
  letter-spacing: 0.06em;
}

.btn--text {
  background: none;
  border: none;
  font-family: "Poppins";
  padding: 12px 30px;
  letter-spacing: 0.06em;
}

.divider {
  height: 5px;
  background: #8cad78;
}

.dividerv2 {
  height: 5px;
  background: #fff;
}

.suspenseLoader {
  width: 100vw;
}

::-webkit-scrollbar {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 40px auto 0px auto;
}

button {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .btn--outlined {
    font-size: 14px;
    padding: 8px 30px;
  }
}

/* Animations */

.reveal {
  transform: translateY(-150px);
  transition: all 1.2s ease;
  opacity: 0;
  color: transparent;
}

.reveal.revealShow {
  transform: translateY(0px);
  opacity: 1;
  color: inherit;
}

.revealhorizontal {
  transform: translateX(400px);
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.revealhorizontal.revealhorizontalShow {
  transform: translateX(0px);
  opacity: 1;
}

.mouseHover {
  opacity: 0.6;
  transition: all 0.5s ease-in-out;
}

.visibleText {
  opacity: 1;
}

.nodata__image {
  height: 500px;
  width: 500px;
  margin: 20px auto;
}

.cursor,
.cursor2 {
  position: absolute;
  border-radius: 100px;
  height: 15px;
  border: 3px solid rgba(255, 255, 255, 0.6);
  width: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999999;
  top: -30px;
  left: -30px;
  cursor: pointer;
}

.cursor2 {
  top: -10px;
  left: -10px;
  transition: all 0.2s ease;
  box-shadow: none;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  .cursor,
  .cursor2 {
    position: absolute;
    border-radius: 100px;
    height: 15px;
    border: 3px solid rgba(255, 255, 255, 0.6);
    width: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999999;
    top: -30px;
    left: -30px;
    cursor: pointer;
    display: none;
  }
  .nodata__image {
    height: 300px;
    width: auto;
    margin: 20px auto;
  }
}

.padding-none {
  padding: 0px !important;
}

.nodata__Image__Align {
  text-align: center;
}
